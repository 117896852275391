<template>
  <div class="header-wrap">
    <header class="header">
      <div class="header-inner">
        <div class="left">
          <router-link to="/">
            <div class="logo-wrap">
              <img src="/logo.png" class="logo" />
              <h1>{{ PROJECT_NAME }}</h1>
            </div>
          </router-link>
          <div class="form-wrap">
            <div class="search-form">
              <input
                autocomplete="off"
                type="text"
                placeholder="请输入你要搜索的关键词"
                class="input"
                @click.stop=""
                @keyup.enter="submit"
                v-model="keyword"
                autofocus
              />
              <span class="search-button" @click="submit"> </span>
            </div>
          </div>
        </div>
        <div class="right">
          <span class="channel hover" @click="goInvite">{{
            isMobile ? "渠道代理" : "网站渠道代理"
          }}</span>
          <span class="channel hover" @click="goSubmitResource">提交资源</span>
          <van-button
            v-if="!userinfo"
            @click="showLogin"
            type="primary"
            size="small"
          >
            登录
          </van-button>
          <span v-if="userinfo" @click="showInfoMask" class="cookie">
            <template v-if="!isMobile">
              用户:{{ userinfo.unionid.substr(0, 8) }}*
            </template>
            <template v-else>用户中心</template>
          </span>
        </div>
      </div>
    </header>
    <van-dialog
      class="info-dialog"
      v-model="infomask"
      :before-close="closeMask"
      :show-confirm-button="false"
    >
      <div class="content" v-if="userinfo">
        <p class="uid">用户ID:{{ userinfo.unionid }}</p>
        <p class="uid" v-if="userinfo.email">邮箱账号:{{ userinfo.email }}</p>
        <div class="vipinfo">
          <div class="section">
            <div class="info-line">
              <div class="left">
                <template v-if="userinfo.expired">
                  <p v-if="userinfo.expiredate" class="danger">
                    有效期：{{ userinfo.expiredate | formatDate }} 已过期
                  </p>
                  <p v-else class="danger">你尚未成为会员</p>
                </template>
                <p v-else class="success">
                  有效期：<span
                    v-if="
                      moment()
                        .add(40, 'years')
                        .isBefore(moment(userinfo.expiredate))
                    "
                    >永久有效</span
                  >
                  <span v-else>{{ userinfo.expiredate | formatDate }}</span>
                </p>
              </div>
              <van-button size="mini" round type="primary" @click="upgrade">{{
                !userinfo.expiredate ? "立刻捐赠并成为会员" : "立即捐赠"
              }}</van-button>
            </div>
            <div class="info-line" v-if="userinfo.showBind">
              <div class="left">
                <p class="small bold">如果之前已是会员，请先绑定原有账户。</p>
              </div>
              <van-button
                size="mini"
                round
                type="primary"
                @click="showBindDialog"
                >点此绑定</van-button
              >
            </div>
          </div>
          <!-- <div class="section" v-if="userinfo.invitecode">
            <div class="info-line">
              <div class="left">
                <p class="success">邀请链接：</p>
                <p class="small">
                  {{ DOMAIN }}/#/?code={{ userinfo.invitecode }}
                </p>
              </div>
              <van-button
                size="mini"
                round
                type="primary"
                @click="copyInviteLink"
                >复制邀请链接</van-button
              >
            </div>
            <p class="tip">成功邀请一名新用户注册，增加3天会员时间</p>
          </div> -->
          <div class="section" v-if="userinfo.isAdmin">
            <div class="info-line">
              <div class="left">
                当前用户为管理员
              </div>
              <van-button size="mini" round type="primary" @click="goAdmin"
                >进入管理页面</van-button
              >
            </div>
          </div>
          <div class="section">
            <p class="tip">登录、使用、捐赠、退捐等各类问题请联系客服！</p>
            <p style="font-size: 15px; margin-top: 5px; font-weight: bold;">
              客服QQ：3556044645
            </p>
          </div>

          <!-- <div class="record-info section" v-if="userinfo.recentRecords.length > 0">
            <p class="record-header">最近24小时付款订单</p>
            <template v-for="item in userinfo.recentRecords">
              <div class="record-item">
                <div class="left">
                  <span class="label">金额:{{item.fee}}元</span>
                  <span class="time">{{item.updatetime | formatTime}}</span>
                </div>
                <van-button size="mini" round  type="primary" @click="refund(item.tradeno)">退款</van-button>
              </div>
            </template>
          </div> -->
        </div>
        <p class="logout tip">
          <a href="javascript:void(0);" @click="logout">清除Cookie退出</a>
        </p>
      </div>
      <van-icon class="close" name="cross" @click="infomask = false" />
    </van-dialog>
  </div>
</template>
<script>
import { logout } from "../api/user";
import { refundOrder } from "../api/pay";
import copyToClipboard from "../utils/clipboard";
import moment from "moment";
export default {
  data() {
    return {
      keyword: "",
      moment,
      isFrame: false,
      infomask: false,
    };
  },
  mounted() {
    if (window.self != window.top) {
      this.isFrame = true;
    }
    setTimeout(() => {
      if (this.$route.query.keyword) this.keyword = this.$route.query.keyword;
    }, 10);
  },
  methods: {
    goInvite() {
      if (!this.userinfo || !this.userinfo.unionid) {
        this.$toast.fail("请先登录");
        return this.showLoginDialog();
      }
      this.$router.push("/main/invite");
    },
    goSubmitResource() {
      this.$router.push("/main/submitResource");
    },
    submit() {
      if (this.isFrame) {
        return this.openWindow(
          `${window.location.origin}/#/main/search?keyword=${encodeURIComponent(
            this.keyword
          )}`,
          true
        );
      }
      this.$store.commit("changeFilterSearchType", -1);
      return (window.location.href = `${
        window.location.origin
      }/#/main/search?keyword=${encodeURIComponent(this.keyword)}`);
    },
    async logout() {
      const res = await logout();
      if (res.code == 0) {
        this.$toast.success("退出成功");
        window.location.reload();
      } else {
        return this.$toast.fail("稍后重试");
      }
    },
    showBindDialog() {
      this.showBindUserDialog();
      this.infomask = false;
    },
    async showLogin() {
      this.showLoginDialog();
    },
    closeMask(_, done) {
      this.infomask = false;
      done();
    },
    async showInfoMask() {
      await this.updateUserinfo();
      this.infomask = true;
    },
    upgrade() {
      this.showPayDialog();
      this.infomask = false;
    },
    goAdmin() {
      this.$router.push("/main/admin");
    },
    refund(tradeno) {
      refundOrder({ order_id: tradeno }).then(() => {
        this.$toast.success("退款成功，将在1小时内到账");
        this.updateUserinfo();
      });
    },
    copyInviteLink() {
      copyToClipboard(`${this.DOMAIN}/#/?code=${this.userinfo.invitecode}`);
      this.$toast("复制成功");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_base.scss";
@import "@/assets/scss/_var.scss";
.header-wrap {
  height: 61px;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 12px 0;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  z-index: 10;
  .header-inner {
    width: 1000px;
    margin: 0 auto;
    display: flex;
    max-width: 100%;
    justify-content: space-between;
    align-items: center;
    a {
      text-decoration: none;
    }
    .left,
    .right {
      display: flex;
      align-items: center;
    }
    .logo-wrap {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 10px;
      .logo {
        height: 40px;
        width: auto;
        margin-right: 5px;
      }
      h1 {
        font-size: 1.6rem;
        flex-shrink: 0; // 解决 safari 下面样式问题
        color: $theme-color;
      }
    }
    .form-wrap {
      .search-form {
        position: relative;
        display: inline-block;
        &:hover {
          .input {
            border-color: rgba(223, 225, 229, 1);
          }
        }
        .input {
          width: 320px;
          font-size: 13px;
          border-radius: 30px;
          height: 36px;
          padding-left: 10px;
          padding-right: 50px;
          outline: none;
          border: 1px solid #ddd;
          background-color: #f8f8f8;
          transition: all ease 0.3s;
          &:focus {
            border-color: $theme-color;
          }
        }
        .search-button {
          display: inline-block;
          position: absolute;
          bottom: 5px;
          width: 28px;
          right: 5px;
          top: 5px;
          z-index: 2;
          outline: none;
          border: none;
          border-radius: 50%;
          cursor: pointer;
          background-color: $theme-color;
          transition: all ease 0.2s;
          background: $theme-color url("../assets/images/search.png") no-repeat
            center center;
          background-size: 14px 14px;
          &:hover {
            background-color: darken($theme-color, 5%);
          }
          &:active {
            background-color: darken($theme-color, 10%);
          }
        }
      }
    }
    .channel {
      margin-right: 12px;
      cursor: pointer;
    }

    .van-button {
      padding: 0 10px;
    }

    .avatar {
      cursor: pointer;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      border: 1px solid #ddd;
    }
    .cookie {
      color: #666;
      cursor: pointer;
    }
  }
}

.van-dialog {
  width: 380px;
  overflow: visible;
  .content {
    text-align: center;
    padding: 20px;
  }
  .tip {
    font-size: 13px;
    color: #666;
    &.logout {
      margin-top: 20px;
    }
    a {
      font-size: 13px;
      margin-top: 10px;
    }
  }
  &.info-dialog {
    .uid {
      font-size: 12px;
      margin-top: 10px;
    }
    .section {
      margin-top: 12px;
      padding: 12px 10px;
      border-radius: 4px;
      background-color: rgba($theme-color, 0.1);
    }
    .vipinfo {
      .info-line {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        text-align: left;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .small {
        margin-top: 5px;
      }
      .small,
      .tip {
        font-size: 12px;
        color: #666;
        font-weight: normal;
        &.bold {
          font-weight: bold;
        }
      }
      .left {
        font-weight: bold;
      }
      .em {
        margin-bottom: 5px;
      }
      .van-button {
        padding: 0 10px;
        flex: none;
        margin-left: 10px;
      }
    }
  }
  .record-info {
    .record-header {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .record-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 5px;
      .left {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #666;
        .label {
          padding-right: 6px;
        }
      }
      .van-button {
        padding: 0 10px;
        flex: none;
      }
    }
  }
  .close {
    position: absolute;
    height: 36px;
    width: 36px;
    bottom: -46px;
    left: 50%;
    margin-left: -18px;
    color: #fff;
    font-size: 24px;
    border-radius: 50%;
    line-height: 36px;
    text-align: center;
    background-color: rgba(#000, 0.7);
    &:hover {
      cursor: pointer;
      background-color: #000;
    }
  }
}

@media (max-width: 700px) {
  .header-wrap {
    height: 57px;
  }
  .header {
    padding: 10px;
    .header-inner {
      .logo-wrap {
        .logo {
          height: 30px;
          margin-right: 0;
        }
        h1 {
          display: none;
        }
      }
      .channel {
        margin-right: 12px;
        font-size: 12px;
      }
      .cookie {
        font-size: 12px;
      }
      .form-wrap {
        .search-form {
          input {
            width: 170px !important;
          }
        }
      }
    }
  }
}
</style>
