<template>
  <div class="app">
    <header-component ref="header" />
    <div class="app-inner">
      <van-notice-bar left-icon="volume-o" :scrollable="isMobile">
        备用站点：<a
          :href="getBeiYongDomain()"
          class="em theme"
          target="_blank"
          >{{ getBeiYongDomain() }}</a
        >
        <template v-if="getSelfBeiYongDomain()">
          ，<a
            :href="getSelfBeiYongDomain()"
            class="em theme"
            target="_blank"
            >{{ getSelfBeiYongDomain() }}</a
          >
        </template>
        ，请保存到浏览器书签中。
      </van-notice-bar>
      <router-view />
    </div>
  </div>
</template>

<script>
import HeaderComponent from "../components/Header.vue";
export default {
  name: "Home",
  components: {
    HeaderComponent,
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/var";
.app {
  background-color: #f8f8f8;
  padding: 15px;
  .app-inner {
    max-width: 100%;
    width: $theme-width;
    margin: 0 auto;
  }
  .van-notice-bar {
    margin-bottom: 10px;
    border-radius: 4px;
  }
}
@media (max-width: 700px) {
  .app {
    padding: 10px;
  }
}
</style>
